import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { usePickSubSectionArticles } from "scmp-app/components/section/section-post-magazine/hooks";
import type { onePostMagSectionTopWidgetArticles$key } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetArticles.graphql";
import type { onePostMagSectionTopWidgetSection$key } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetSection.graphql";
import type { onePostMagSectionTopWidgetSponsorQueueItemsEdge$key } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetSponsorQueueItemsEdge.graphql";

import type { SectionTopWidgetOneProps } from "./styles";
import {
  AdsContainer,
  Container,
  Description,
  Divider,
  SectionInfoWrapper,
  SectionName,
  StyledContentItemPostMagazinePrimary,
  StyledSectionNameEntityLink,
} from "./styles";

export type Props = {
  adsConfigs?: [AdSlotProps, AdSlotProps][];
  className?: string;
  label?: string;
  randomSeed?: string;
  reference: onePostMagSectionTopWidgetArticles$key;
  sectionReference?: onePostMagSectionTopWidgetSection$key;
  sponsorReference?: onePostMagSectionTopWidgetSponsorQueueItemsEdge$key;
  variant?: SectionTopWidgetOneProps["$variant"];
};
export const SectionTopWidgetOne: FunctionComponent<Props> = ({
  adsConfigs,
  className,
  label,
  randomSeed,
  reference: reference_,
  sectionReference,
  sponsorReference,
  variant = "default",
}) => {
  const section = useFragment(
    graphql`
      fragment onePostMagSectionTopWidgetSection on Section {
        name
        description {
          text
        }
        ...entityLink
      }
    `,
    sectionReference ?? null,
  );

  const data = useFragment(
    graphql`
      fragment onePostMagSectionTopWidgetArticles on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...postMagazinePrimaryContent
          }
        }
      }
    `,
    reference_,
  );

  const sponsorData = useFragment(
    graphql`
      fragment onePostMagSectionTopWidgetSponsorQueueItemsEdge on QueueItemsEdge
      @relay(plural: true) {
        node {
          ... on Content {
            ...postMagazinePrimaryContent
          }
        }
      }
    `,
    sponsorReference ?? null,
  );

  const sponsorArticle = usePickSubSectionArticles(
    randomSeed ?? "fallback seed",
    sponsorData,
    !!sponsorReference,
  );

  const getArticles = () => {
    switch (variant) {
      case "withFiveArticle":
        return [...data.slice(0, 5), sponsorArticle];
      case "withThreeArticle":
        return data.slice(0, 3);
      default:
        return data.slice(0, 4);
    }
  };
  const firstAdConfig = adsConfigs?.[0];

  return (
    <Container $variant={variant} className={className}>
      {getArticles().map((edge, index) => {
        const showMobileSquareImage = variant === "withThreeArticle" && index === 0;
        if (!edge) return null;
        return (
          <StyledContentItemPostMagazinePrimary
            $showMobileSquareImage={showMobileSquareImage}
            imageResponsiveVariants={{
              desktopUp: "size1200x800",
              mobileUp: showMobileSquareImage ? "size768x768" : "size540x360",
              tabletUp: "size1200x800",
            }}
            key={index}
            reference={edge.node}
            topicLinkVariant={{ type: "main" }}
          />
        );
      })}

      <SectionInfoWrapper>
        {label ? (
          <SectionName>{label}</SectionName>
        ) : (
          section && (
            <StyledSectionNameEntityLink reference={section}>
              <SectionName>{section.name || ""}</SectionName>
            </StyledSectionNameEntityLink>
          )
        )}
        {section && <Description>{section.description?.text}</Description>}
      </SectionInfoWrapper>

      <Divider />
      {firstAdConfig && (
        <AdsContainer>
          <AdSlot {...firstAdConfig[0]} />
          <AdSlot {...firstAdConfig[1]} />
        </AdsContainer>
      )}
    </Container>
  );
};

SectionTopWidgetOne.displayName = "SectionTopWidgetOne";
