import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { tracking } from "scmp-app/data";
import type { asiaWidgetQueueQuery$key } from "scmp-app/queries/__generated__/asiaWidgetQueueQuery.graphql";

import {
  Container,
  ContentItemsContainer,
  MoreLink,
  MoreLinkContainer,
  Row,
  StyledContentItemHomeSecondary,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: asiaWidgetQueueQuery$key;
};

export const AsiaWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment asiaWidgetQueueQuery on Query {
        asiaSection: section(filter: { entityId: "3" }) {
          urlAlias
        }
        asiaQueue: queue(filter: { name: "section_top_3" }) {
          items(first: 5) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...homeSecondaryContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const asiaItems = data?.asiaQueue?.items?.edges ?? [];

  if (asiaItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.AsiaLatest,
      }}
    >
      <Container className={className}>
        <TitleContainer>
          <Title pathname={data?.asiaSection?.urlAlias}>LATEST ASIA NEWS</Title>
        </TitleContainer>

        <ContentItemsContainer>
          <Row>
            {asiaItems?.map(({ node }) => (
              <StyledContentItemHomeSecondary
                key={node.entityId}
                reference={node}
                withComment
                withImage
                withSection
              />
            ))}
          </Row>
        </ContentItemsContainer>
        <MoreLinkContainer>
          <MoreLink pathname={data?.asiaSection?.urlAlias}>MORE ASIA NEWS</MoreLink>
        </MoreLinkContainer>
      </Container>
    </BaseLinkContextProvider>
  );
};

AsiaWidget.displayName = "AsiaWidget";
