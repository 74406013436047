import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { AdSlotContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import {
  H1Container as HeadlineH1Container,
  H2Container as HeadlineH2Container,
} from "scmp-app/components/content/content-headline/styles";
import {
  ContentItemMagazinesStyleCardSquare,
  ContentItemMagazinesStyleCardWide,
} from "scmp-app/components/content/content-item-render/variants/magazines-style-card";
import {
  Headline as MagazineStyleCardHeadline,
  Topic as MagazineStyleCardTopic,
} from "scmp-app/components/content/content-item-render/variants/magazines-style-card/styles";
import { ContentItemMagazinesStyleLead } from "scmp-app/components/content/content-item-render/variants/magazines-style-lead";
import { CoverContainer as MagazineStyleLeadCoverContainer } from "scmp-app/components/content/content-item-render/variants/magazines-style-lead/styles";
import { StyledDiv } from "scmp-app/components/schema-render/common/div/styles";
import { SectionStyleSection } from "scmp-app/components/section/section-style/section-style-section";
import {
  SectionNameText,
  StyledBaseImage,
} from "scmp-app/components/section/section-style/section-style-section/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const InlineAdSlot = styled(AdSlot)`
  flex-direction: column-reverse;

  inline-size: 300px;
`;

export const DesktopAdSlot = styled(InlineAdSlot)`
  justify-content: flex-end;
  align-self: start;
  ${AdSlotContainer} {
    min-block-size: min-content;
  }

  ${props => props.theme.breakpoints.only("tablet")} {
    grid-area: ad-slot;

    justify-content: flex-end;
  }
`;
export const MobileAdSlot = styled(InlineAdSlot)`
  margin-block-start: 16px;
`;

export const BannerAdSlot = styled(AdSlot)`
  flex-direction: column;
`;
export const DesktopBannerAdSlot = styled(BannerAdSlot)`
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 24px;
  }
`;
export const MobileBannerAdSlot = styled(BannerAdSlot)`
  flex-direction: column-reverse;

  margin-block-start: 16px;
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;

  inline-size: 100%;
  max-inline-size: 1180px;

  ${props => props.theme.breakpoints.up("tablet")} {
    row-gap: 48px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    row-gap: 64px;
  }
  ${props => props.theme.breakpoints.up("largeDesktop")} {
    row-gap: 88px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  inline-size: 100%;
`;

export const StyledContentItemMagazinesStyleLead = styled(ContentItemMagazinesStyleLead)``;

export const StyledContentItemMagazinesStyleCard = styled(ContentItemMagazinesStyleCardSquare)`
  inline-size: 100%;
  block-size: auto;
`;

export const StyledContentItemMagazinesStyleCardWide = styled(ContentItemMagazinesStyleCardWide)`
  inline-size: 100%;
  block-size: auto;
`;

export const ThreeColumnArticleContainer = styled.div`
  display: grid;
  grid:
    "first-article" min-content
    "second-article" min-content
    "ad-slot" min-content
    / 1fr;
  row-gap: 48px;
  justify-items: center;

  ${StyledContentItemMagazinesStyleCard}, ${StyledContentItemMagazinesStyleCardWide} {
    inline-size: 100%;
    &:nth-child(1) {
      grid-area: first-article;
    }
    &:nth-child(2) {
      grid-area: second-article;
    }
  }

  ${DesktopAdSlot}, ${MobileAdSlot} {
    grid-area: ad-slot;

    justify-content: center;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    display: grid;
    grid:
      "first-article ad-slot" min-content
      "second-article ad-slot" min-content
      / 1fr 300px;
    gap: 24px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    display: grid;
    grid:
      "first-article second-article ad-slot" min-content
      / 1fr 1fr 300px;
  }

  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    gap: 40px;
  }
`;

export const ThreeColumnWithRowSpanArticleContainer = styled.div`
  display: grid;
  grid:
    "main-article" min-content
    "mid-upper-article" min-content
    "mid-lower-article" min-content
    "ad-slot" min-content
    / 1fr;
  row-gap: 40px;
  justify-items: center;

  inline-size: 100%;

  ${StyledContentItemMagazinesStyleCard}, ${StyledContentItemMagazinesStyleCardWide} {
    &:nth-child(1) {
      grid-area: main-article;
    }
    &:nth-child(2) {
      grid-area: mid-upper-article;
    }
    &:nth-child(3) {
      grid-area: mid-lower-article;
    }

    &:nth-child(2),
    &:nth-child(3) {
      ${props => props.theme.breakpoints.up("tablet")} {
        ${MagazineStyleCardHeadline} {
          ${HeadlineH1Container}, ${HeadlineH2Container} {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }

      ${props => props.theme.breakpoints.up("desktop")} {
        ${MagazineStyleCardTopic} {
          margin-block-start: 6px;
        }
      }
    }
  }

  ${DesktopAdSlot}, ${MobileAdSlot} {
    grid-area: ad-slot;
  }
  ${props => props.theme.breakpoints.up("tablet")} {
    display: grid;
    grid:
      "main-article ad-slot" min-content
      "mid-upper-article ad-slot" min-content
      "mid-lower-article ad-slot" min-content
      / 1fr 300px;

    column-gap: 24px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    display: grid;
    grid:
      "main-article mid-upper-article ad-slot" min-content
      "main-article mid-lower-article ad-slot" min-content
      / 1fr 0.75fr 300px;
    row-gap: 40px;
  }
  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    grid-template-columns: 1fr 0.63fr 300px;
    gap: 40px;
  }
`;

export const TwoColumnArticleContainer = styled.div`
  display: grid;
  grid:
    "first-article" min-content
    "second-article" min-content
    / 1fr;
  row-gap: 40px;

  margin-block-start: 4px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "first-article second-article" min-content
      / 1fr 1fr;

    column-gap: 24px;
  }

  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    column-gap: 40px;
  }
`;

export const Adslot = styled.div`
  inline-size: 100%;
  block-size: 600px;

  border: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 300px;
  }
`;

type LeadArticleProps = {
  $withFullWidth?: boolean;
};
export const LeadArticle = styled.div<LeadArticleProps>`
  inline-size: 100%;

  ${props => props.theme.breakpoints.only("mobile")} {
    ${MagazineStyleLeadCoverContainer} {
      figure,
      ${StyledDiv} {
        ${setFullViewportWidthStyles()}
      }
    }
  }

  ${MagazineStyleLeadCoverContainer} {
    inline-size: 100%;
  }

  ${MagazineStyleLeadCoverContainer} > a {
    display: block;
  }

  /* always 100vw */
  ${props =>
    props.$withFullWidth &&
    css`
      ${MagazineStyleLeadCoverContainer} {
        inline-size: 1440px;

        figure,
        ${StyledDiv} {
          inline-size: 1440px;
        }
      }

      ${props.theme.breakpoints.down("largeDesktop")} {
        ${MagazineStyleLeadCoverContainer} {
          figure,
          ${StyledDiv} {
            ${setFullViewportWidthStyles()}
          }
        }
      }
    `}
`;

export const StyledSectionStyleSection = styled(SectionStyleSection)`
  margin-block: 56px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block: 48px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block: 64px;
  }

  ${StyledBaseImage} {
    block-size: 30px;
    ${props => props.theme.breakpoints.up("tablet")} {
      block-size: 35px;
    }
    ${props => props.theme.breakpoints.up("desktop")} {
      block-size: 47px;
    }
  }

  ${SectionNameText} {
    font-size: 30px;
    line-height: 30px;
    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 48px;
      line-height: 48px;
    }
    ${props => props.theme.breakpoints.up("desktop")} {
      font-size: 64px;
      line-height: 64px;
    }
  }
`;
