import { theme } from "@product/scmp-sdk";
import { Fragment, type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { useSectionContext } from "scmp-app/components/section/contexts";
import type { sectionStyleMainQuery$key } from "scmp-app/queries/__generated__/sectionStyleMainQuery.graphql";

import type { Props as SectionStyleMainArticleListProps } from "./section-style-main-article-list";
import { SectionStyleMainHead } from "./section-style-main-head";
import { SectionStyleSpotlight } from "./section-style-spotlight";
import {
  BannerAdSlot,
  Container,
  Divider,
  StyledSectionStyleFashionCarousel,
  StyledSectionStyleMainArticleList,
  StyledSectionStyleVideoWidget,
  TopThreeArticleList,
} from "./styles";

export type Props = {
  className?: string;
  reference: sectionStyleMainQuery$key;
};
export const SectionStyleMain: FunctionComponent<Props> = ({ className, reference }) => {
  const {
    beautyArticleQueue,
    beautySection,
    fashionArticleQueue,
    fashionSection,
    lifestyleArticleQueue,
    lifestyleSection,
    luxuryArticleQueue,
    luxurySection,
    peopleArticleQueue,
    peopleSection,
    topArticleQueue,
    ...data
  } = useFragment(
    graphql`
      fragment sectionStyleMainQuery on Query
      @argumentDefinitions(articlesQueueName: { type: "String!" }) {
        topArticleQueue: queue(filter: { name: $articlesQueueName }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 3)
        }

        luxurySection: section(filter: { entityId: "322897" }) {
          ...sectionStyleMainArticleListSection
        }
        luxuryArticleQueue: queue(filter: { name: "section_top_322897" }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 4)
        }

        fashionSection: section(filter: { entityId: "516294" }) {
          ...sectionStyleMainArticleListSection
        }
        fashionArticleQueue: queue(filter: { name: "section_top_516294" }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 4)
        }

        beautySection: section(filter: { entityId: "516298" }) {
          ...sectionStyleMainArticleListSection
        }
        beautyArticleQueue: queue(filter: { name: "section_top_516298" }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 3)
        }

        peopleSection: section(filter: { entityId: "516301" }) {
          ...sectionStyleMainArticleListSection
        }
        peopleArticleQueue: queue(filter: { name: "section_top_516301" }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 3)
        }

        lifestyleSection: section(filter: { entityId: "516305" }) {
          ...sectionStyleMainArticleListSection
        }
        lifestyleArticleQueue: queue(filter: { name: "section_top_516305" }) {
          ...sectionStyleMainArticleListQueue @arguments(first: 3)
        }
        ...sectionStyleFashionCarouselQuery
        ...sectionStyleSpotlightQuery
        ...sectionStyleVideoWidgetQuery
      }
    `,
    reference,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();

  const sections: SectionStyleMainArticleListProps[] = [
    {
      inlineAdslotProps: {
        desktop: {
          ...baseAdvertisement,
          adUnit: "d_lrec3",
          breakpoint: theme.breakpoints.up("tablet"),
          sizes: [[300, 600], [300, 250], "fluid"],
          stickyLabel: true,
          stickySpacing: 40,
          withLabel: true,
          withSticky: true,
        },
        mobile: {
          ...baseAdvertisement,
          adUnit: "m_lrec1",
          breakpoint: theme.breakpoints.only("mobile"),
          sizes: [[300, 250], [320, 480], "fluid"],
          withLabel: true,
        },
      },
      reference: luxuryArticleQueue,
      sectionReference: luxurySection,
      variant: "three-column-with-row-span",
    },
    {
      children: <StyledSectionStyleFashionCarousel reference={data} />,
      inlineAdslotProps: {
        desktop: {
          ...baseAdvertisement,
          adUnit: "d_lrec2",
          breakpoint: theme.breakpoints.up("tablet"),
          sizes: [[300, 600], [300, 250], "fluid"],
          stickyLabel: true,
          stickySpacing: 40,
          withLabel: true,
          withSticky: true,
        },
        mobile: {
          ...baseAdvertisement,
          adUnit: "m_lrec3",
          breakpoint: theme.breakpoints.only("mobile"),
          sizes: [[300, 250], [320, 480], "fluid"],
          withLabel: true,
        },
      },
      reference: fashionArticleQueue,
      sectionReference: fashionSection,
      variant: "three-column-with-row-span",
    },
    {
      bannerAdslotProps: {
        desktop: {
          ...baseAdvertisement,
          adUnit: "d_banner2",
          breakpoint: theme.breakpoints.up("desktop"),
          sizes: [[970, 250], "fluid"],
          withLabel: true,
        },
        mobile: {
          ...baseAdvertisement,
          adUnit: "m_banner4",
          breakpoint: theme.breakpoints.down("desktop"),
          sizes: [[300, 50], [300, 100], [320, 50], [320, 100], "fluid"],
          withLabel: true,
        },
      },
      reference: beautyArticleQueue,
      sectionReference: beautySection,
      variant: "two-column",
    },
    {
      inlineAdslotProps: {
        desktop: {
          ...baseAdvertisement,
          adUnit: "d_lrec4",
          breakpoint: theme.breakpoints.up("tablet"),
          sizes: [[300, 600], [300, 250], "fluid"],
          stickyLabel: true,
          stickySpacing: 40,
          withLabel: true,
          withSticky: true,
        },
        mobile: {
          ...baseAdvertisement,
          adUnit: "m_lrec3c",
          breakpoint: theme.breakpoints.only("mobile"),
          sizes: [[300, 250], [320, 480], "fluid"],
          withLabel: true,
        },
      },
      reference: peopleArticleQueue,
      sectionReference: peopleSection,
      variant: "three-column",
    },
    {
      children: <StyledSectionStyleVideoWidget reference={data} />,
      inlineAdslotProps: {
        desktop: {
          ...baseAdvertisement,
          adUnit: "d_lrec4",
          breakpoint: theme.breakpoints.up("tablet"),
          sizes: [[300, 600], [300, 250], "fluid"],
          stickyLabel: true,
          stickySpacing: 40,
          withLabel: true,
          withSticky: true,
        },
        mobile: {
          ...baseAdvertisement,
          adUnit: "m_lrec2",
          breakpoint: theme.breakpoints.only("mobile"),
          sizes: [[300, 250], [320, 480], "fluid"],
          withLabel: true,
        },
      },
      reference: lifestyleArticleQueue,
      sectionReference: lifestyleSection,
      variant: "three-column",
    },
  ];

  const handleRenderSpotlight = useCallback(
    (index: number) => {
      // Insert spotlight before the last section
      if (index !== sections.length - 1) return;
      return (
        <>
          <SectionStyleSpotlight reference={data} />
          <Divider />
        </>
      );
    },
    [data, sections.length],
  );

  return (
    <Container className={className}>
      <SectionStyleMainHead />
      <TopThreeArticleList
        inlineAdslotProps={{
          desktop: {
            ...baseAdvertisement,
            adUnit: "d_lrec1",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 600], [300, 250], "fluid"],
            stickyLabel: true,
            stickySpacing: 40,
            withLabel: true,
            withSticky: true,
          },
          mobile: {
            ...baseAdvertisement,
            adUnit: "m_banner1",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], [1, 1], "fluid"],
            withLabel: true,
          },
        }}
        reference={topArticleQueue}
        variant="three-column"
        withFullWidthLead
        withLeadingCoverVideo
      />
      <BannerAdSlot
        {...baseAdvertisement}
        adUnit="d_banner5"
        breakpoint={theme.breakpoints.up("tablet")}
        sizes={[[970, 250], [300, 250], [1, 1], "fluid"]}
        withLabel
      />
      {sections.map((props, index) => (
        <Fragment key={index}>
          <Divider />
          {handleRenderSpotlight(index)}
          <StyledSectionStyleMainArticleList {...props} />
        </Fragment>
      ))}
    </Container>
  );
};

SectionStyleMain.displayName = "SectionStyleMain";
