/**
 * @generated SignedSource<<f8edfc0e4172e5bf793867642a06ce4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type asiaWidgetQueueQuery$data = {
  readonly asiaQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly asiaSection: {
    readonly urlAlias: string;
  };
  readonly " $fragmentType": "asiaWidgetQueueQuery";
};
export type asiaWidgetQueueQuery$key = {
  readonly " $data"?: asiaWidgetQueueQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"asiaWidgetQueueQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "asiaWidgetQueueQuery",
  "selections": [
    {
      "alias": "asiaSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "3"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"3\"})"
    },
    {
      "alias": "asiaQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_3"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 5
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "homeSecondaryContentItemContent"
                        }
                      ],
                      "type": "Article",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:5)"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_top_3\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f21d74a6c534fe84da095d1ce3d08e9a";

export default node;
