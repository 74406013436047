/**
 * @generated SignedSource<<3e23c147bd51dcc8dd97c9719d9159e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSectionWidgetQuery$data = {
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"asiaWidgetQueueQuery" | "chinaScienceDiscoveryModuleQueueQuery" | "commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "focusArticleHomeFocusArticleQuery" | "mostPopularQueueQuery" | "multimediaQuery" | "openQuestionsSeriesDiscoveryModuleQuery" | "plusWidgetQuery" | "thisWeekInAsiaWidgetQueue" | "trendingTopicTopic">;
  readonly " $fragmentType": "hooksSectionWidgetQuery";
};
export type hooksSectionWidgetQuery$key = {
  readonly " $data"?: hooksSectionWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSectionWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSectionWidgetQuery"
};

(node as any).hash = "8de39777ebad5df1825661965cc70a6c";

export default node;
