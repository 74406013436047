import styled from "@emotion/styled";
import { fontHeebo } from "@product/scmp-sdk";

import { ContentCoverImage } from "scmp-app/components/content/content-cover-image";
import { SponsorHeadline as ContentSponsorHeadlineSpan } from "scmp-app/components/content/content-headline/styles";
import { SectionLink } from "scmp-app/components/content/content-sections/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  margin-block-start: 8px;

  color: #000000;
`;

export const Section = styled.div`
  ${SectionLink} {
    color: #4585ff;
    font-size: 13px;
  }
`;

export const StyledContentCoverImage = styled(ContentCoverImage)``;

export const Headline = styled.div`
  margin-block-end: 12px;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontHeebo};
  line-height: 23.4px;

  ${ContentSponsorHeadlineSpan} {
    font-weight: 700;
    font-style: normal;
    line-height: 22.4px;
  }
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
  }
`;

export const Primary = styled.div``;
