import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { tracking } from "scmp-app/data";
import type { thisWeekInAsiaWidgetQueue$key } from "scmp-app/queries/__generated__/thisWeekInAsiaWidgetQueue.graphql";

import {
  Container,
  ContentItemsContainer,
  HighlightsFrom,
  MoreLink,
  MoreLinkContainer,
  Row,
  StyledContentItemSectionTwia,
  Title,
  TitleContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: thisWeekInAsiaWidgetQueue$key;
};

export const ThisWeekInAsiaWidget: FunctionComponent<Props> = ({ className, reference }) => {
  const data = useFragment(
    graphql`
      fragment thisWeekInAsiaWidgetQueue on Query {
        twiaSection: section(filter: { entityId: "323045" }) {
          urlAlias
        }
        twiaQueue: queue(filter: { name: "section_top_323045" }) {
          items(first: 5) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...sectionTwiaContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const twiaItems = data?.twiaQueue?.items?.edges ?? [];

  if (twiaItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TwiaHighlight,
      }}
    >
      <Container className={className}>
        <TitleContainer>
          <HighlightsFrom>Highlights from</HighlightsFrom>
          <Title pathname={data?.twiaSection?.urlAlias}>THIS WEEK IN ASIA</Title>
        </TitleContainer>

        <ContentItemsContainer>
          <Row>
            {twiaItems?.map(({ node }, index) => (
              <StyledContentItemSectionTwia key={node.entityId ?? index} reference={node} />
            ))}
          </Row>
        </ContentItemsContainer>
        <MoreLinkContainer>
          <MoreLink pathname={data?.twiaSection?.urlAlias}>MORE IN THIS WEEK IN ASIA</MoreLink>
        </MoreLinkContainer>
      </Container>
    </BaseLinkContextProvider>
  );
};

ThisWeekInAsiaWidget.displayName = "ThisWeekInAsiaWidget";
