import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemSectionTwia } from "scmp-app/components/content/content-item-render/variants/section-twia";
import { ActionBar } from "scmp-app/components/content/content-item-render/variants/section-twia/styles";

export const StyledContentItemSectionTwia = styled(ContentItemSectionTwia)`
  flex: 1;

  ${ActionBar} {
    ${theme.breakpoints.up("tablet")} {
      display: none;
    }

    ${theme.breakpoints.up("desktop")} {
      display: block;
    }
  }
`;

export const HighlightsFrom = styled.div`
  color: #00000080;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const Title = styled(BaseLink)`
  margin-block-end: 16px;

  color: #000000;
  font-weight: 700;
  font-size: 24px;
  font-family: ${fontRobotoCondensed};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
`;

export const ContentItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("desktop")} {
    grid-template-columns: 1fr;
  }
`;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid #00000033;

  ${StyledContentItemSectionTwia}:not(:last-child) {
    border-block-end: 1px solid #0000001a;

    padding-block-end: 18px;

    ${theme.breakpoints.up("tablet")} {
      padding-block-end: 16px;
    }
  }

  ${StyledContentItemSectionTwia}:last-child {
    padding-block-end: 18px;
    ${theme.breakpoints.up("tablet")} {
      border-block-end: 1px solid #0000001a;

      padding-block-end: 16px;
    }

    ${theme.breakpoints.up("desktop")} {
      border-block-end: 0;

      padding-block-end: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${theme.breakpoints.up("tablet")} {
    flex-wrap: wrap;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-wrap: nowrap;
  }

  ${StyledContentItemSectionTwia} {
    ${theme.breakpoints.up("tablet")} {
      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: calc(50% - 20px);
      }
    }
    ${theme.breakpoints.up("desktop")} {
      flex-direction: column;
      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: unset;
      }
    }
  }

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const MoreLinkContainer = styled.div`
  margin-block-start: 20px;
  border-block-start: 1px solid #0000001a;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 0;
    border-block-start: 0;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 20px;
    border-block-start: 1px solid #0000001a;
  }
`;

export const MoreLink = styled(BaseLink)`
  margin-block-start: 16px;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-size: 14px;

  border: 1px solid #4585ff;
  border-radius: 2px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 16px;
  }
`;
