import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { useSectionContext } from "scmp-app/components/section/contexts";
import type { sectionStyleMainArticleListQueue$key } from "scmp-app/queries/__generated__/sectionStyleMainArticleListQueue.graphql";
import type { sectionStyleMainArticleListSection$key } from "scmp-app/queries/__generated__/sectionStyleMainArticleListSection.graphql";

import {
  Container,
  DesktopAdSlot,
  DesktopBannerAdSlot,
  LeadArticle,
  MobileAdSlot,
  MobileBannerAdSlot,
  SectionContainer,
  StyledContentItemMagazinesStyleCard,
  StyledContentItemMagazinesStyleCardWide,
  StyledContentItemMagazinesStyleLead,
  StyledSectionStyleSection,
  ThreeColumnArticleContainer,
  ThreeColumnWithRowSpanArticleContainer,
  TwoColumnArticleContainer,
} from "./styles";

export type Props = {
  bannerAdslotProps?: {
    desktop?: AdSlotProps;
    mobile?: AdSlotProps;
  };
  children?: ReactNode;
  className?: string;
  inlineAdslotProps?: {
    desktop?: AdSlotProps;
    mobile?: AdSlotProps;
  };
  reference: Nullish<sectionStyleMainArticleListQueue$key>;
  sectionReference?: sectionStyleMainArticleListSection$key;
  variant: "three-column" | "three-column-with-row-span" | "two-column";
  withFullWidthLead?: boolean;
  withLeadingCoverVideo?: boolean;
};
export const SectionStyleMainArticleList: FunctionComponent<Props> = ({
  bannerAdslotProps,
  children,
  className,
  inlineAdslotProps,
  reference,
  sectionReference,
  variant,
  withFullWidthLead,
  withLeadingCoverVideo,
}) => {
  const articleQueue = useFragment(
    graphql`
      fragment sectionStyleMainArticleListQueue on Queue
      @argumentDefinitions(first: { type: "Int", defaultValue: 4 }) {
        items(first: $first, exclude: { types: VIDEO })
          @connection(key: "sectionStyleMainArticleListQueue__items") {
          edges {
            node {
              ... on Article {
                ...magazinesStyleLeadContentItemContent
                ...magazinesStyleCardContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );
  const section = useFragment(
    graphql`
      fragment sectionStyleMainArticleListSection on Section {
        ...sectionStyleSectionSection
      }
    `,
    sectionReference ?? null,
  );

  const articles = useMemo(
    () => articleQueue?.items?.edges.slice(0, 4).filter(notEmpty) ?? [],
    [articleQueue],
  );

  const handleRenderArticle = useCallback(
    (children: ReactNode) => {
      switch (variant) {
        case "three-column":
          return <ThreeColumnArticleContainer>{children}</ThreeColumnArticleContainer>;
        case "three-column-with-row-span":
          return (
            <ThreeColumnWithRowSpanArticleContainer>
              {children}
            </ThreeColumnWithRowSpanArticleContainer>
          );
        case "two-column":
          return <TwoColumnArticleContainer>{children}</TwoColumnArticleContainer>;
      }
    },
    [variant],
  );

  const { advertisement } = useSectionContext();

  if (articles.length === 0) return null;
  const { node: leadArticle } = articles[0];
  if (!reference) return null;

  return (
    <Container className={className}>
      {section && <StyledSectionStyleSection reference={section} />}
      <SectionContainer>
        <LeadArticle $withFullWidth={withFullWidthLead}>
          <StyledContentItemMagazinesStyleLead
            advertisement={advertisement}
            reference={leadArticle}
            withLeadingCoverVideo={withLeadingCoverVideo}
          />
        </LeadArticle>
        {handleRenderArticle(
          <>
            {articles
              .slice(1)
              .filter(({ node }) => node !== null)
              .map(({ node }, index) => {
                if (variant === "three-column-with-row-span" && index > 0) {
                  return <StyledContentItemMagazinesStyleCardWide key={index} reference={node} />;
                }
                return <StyledContentItemMagazinesStyleCard key={index} reference={node} />;
              })}
            {inlineAdslotProps?.desktop && <DesktopAdSlot {...inlineAdslotProps.desktop} />}
            {inlineAdslotProps?.mobile && <MobileAdSlot {...inlineAdslotProps.mobile} />}
          </>,
        )}
        {children}
        {bannerAdslotProps?.desktop && <DesktopBannerAdSlot {...bannerAdslotProps.desktop} />}
        {bannerAdslotProps?.mobile && <MobileBannerAdSlot {...bannerAdslotProps.mobile} />}
      </SectionContainer>
    </Container>
  );
};
