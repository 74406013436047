import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { StyledTopStoriesBlock } from "scmp-app/components/section/default-section/styles";
import type { sectionSpotlightQuery$key } from "scmp-app/queries/__generated__/sectionSpotlightQuery.graphql";

import { LogoContainer, StyledLogo, TopStoriesComponent } from "./styles";

type Props = {
  reference: sectionSpotlightQuery$key;
};

export const SectionSpotlight: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const { sectionSpotlightQueue } = useFragment(
    graphql`
      fragment sectionSpotlightQuery on Query
      @argumentDefinitions(sectionSpotlightQueueName: { type: "String!" }) {
        sectionSpotlightQueue: queue(filter: { name: $sectionSpotlightQueueName }) {
          items(first: 4) {
            edges {
              ...topStoriesBlockQueueItemsEdge
            }
          }
        }
      }
    `,
    reference_,
  );

  const sectionSpotlightItems = sectionSpotlightQueue?.items?.edges ?? [];

  return (
    <>
      <LogoContainer>
        <StyledLogo />
      </LogoContainer>
      <TopStoriesComponent>
        <StyledTopStoriesBlock
          onClick={() => {
            // todo: add tracking
          }}
          reference={sectionSpotlightItems}
        />
      </TopStoriesComponent>
    </>
  );
};

SectionSpotlight.displayName = "SectionSpotlight";
